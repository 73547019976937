import { createSharedComposable } from '@vueuse/core'

const _useDashboard = () => {
	const route = useRoute()
	const router = useRouter()
	const isHelpSlideoverOpen = ref(false)
	const isNotificationsSlideoverOpen = ref(false)

	defineShortcuts({
		'g-h': () => router.push('/'),
		'g-i': () => router.push('/inbox'),
		'g-u': () => router.push('/users'),
		'g-s': () => router.push('/settings'),
		'shift_u': () => router.push('/settings/members'),
		'shift_p': () => router.push('/CadastrarProposta'),
		'shift_a': () => router.push('/operacional/EsteiraProducao'),
		'shift_c': () => router.push('/operacional/Convenios'),
		'shift_r': () => router.push('/operacional/RoteirosOperacionais'),
		'shift_b': () => router.push('/operacional/Bancos'),
		'shift_t': () => router.push('/operacional/Produtos'),
		'shift_x': () => router.push('/operacional/Acessos'),
		'?': () => isHelpSlideoverOpen.value = true,
		'n': () => isNotificationsSlideoverOpen.value = true
	})

	watch(() => route.fullPath, () => {
		isHelpSlideoverOpen.value = false
		isNotificationsSlideoverOpen.value = false
	})

	return {
		isHelpSlideoverOpen,
		isNotificationsSlideoverOpen
	}
}

export const useDashboard = createSharedComposable(_useDashboard)
